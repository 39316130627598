// Generated by Framer (5bbf1f3)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Countdown from "https://framerusercontent.com/modules/YA3iK3Afo27kYzYjpTSi/wzOjO4NRX4sgx6glGj2D/Countdown.js";
const CountdownFonts = getFonts(Countdown);
const MotionDivWithFX = withFX(motion.div);

const serializationHash = "framer-b9wHv"

const variantClassNames = {WFXNvBKhI: "framer-v-762bfr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 8}

const transition1 = {delay: 0.2, duration: 0.6, ease: [0.44, 0, 0.56, 1], type: "tween"}

const transition2 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

const Variants = motion.create(React.Fragment)

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "WFXNvBKhI", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition2}><MotionDivWithFX {...restProps} {...gestureHandlers} __framer__animate={{transition: transition1}} __framer__animateOnce __framer__enter={animation} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(scopingClassNames, "framer-762bfr", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WFXNvBKhI"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.14)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backdropFilter: "blur(7px)", background: "linear-gradient(99deg, rgba(64, 178, 76, 0.3) 0%, rgba(255, 255, 255, 0.2) 100%)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, WebkitBackdropFilter: "blur(7px)", ...style}}><ComponentViewportProvider ><motion.div className={"framer-14st3n-container"} layoutDependency={layoutDependency} layoutId={"A2v2xTPKi-container"}><Countdown color={"var(--token-ddce9074-1558-4e8a-8bdd-b6c03f1ece1c, rgb(255, 255, 255))"} date={"2025-01-31T00:00:00.000Z"} displayProps={{dayLabel: "Days", digitCount: false, hourLabel: "H", labelSpace: false, labelType: false, minuteLabel: "M", numberSpace: false, secondLabel: "S", showHours: true, showMinutes: true, showSeconds: true, tabularFont: true}} font={{fontFamily: "\"Inter\", \"Inter Placeholder\", sans-serif", fontSize: "32px", fontStyle: "normal", fontWeight: 600, letterSpacing: "0em", lineHeight: "1em"}} height={"100%"} id={"A2v2xTPKi"} labelColor={"rgba(255, 255, 255, 0.5)"} layoutId={"A2v2xTPKi"} pickTime={0} width={"100%"}/></motion.div></ComponentViewportProvider></MotionDivWithFX></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-b9wHv.framer-1sgaajb, .framer-b9wHv .framer-1sgaajb { display: block; }", ".framer-b9wHv.framer-762bfr { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 24px; height: min-content; justify-content: center; overflow: hidden; padding: 16px 20px 16px 20px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-b9wHv .framer-14st3n-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-b9wHv.framer-762bfr { gap: 0px; } .framer-b9wHv.framer-762bfr > * { margin: 0px; margin-left: calc(24px / 2); margin-right: calc(24px / 2); } .framer-b9wHv.framer-762bfr > :first-child { margin-left: 0px; } .framer-b9wHv.framer-762bfr > :last-child { margin-right: 0px; } }", ".framer-b9wHv[data-border=\"true\"]::after, .framer-b9wHv [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 417.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHnWZ7a18p: React.ComponentType<Props> = withCSS(Component, css, "framer-b9wHv") as typeof Component;
export default FramerHnWZ7a18p;

FramerHnWZ7a18p.displayName = "Countdown";

FramerHnWZ7a18p.defaultProps = {height: 64, width: 417.5};

addFonts(FramerHnWZ7a18p, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/hyOgCu0Xnghbimh0pE8QTvtt2AU.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/NeGmSOXrPBfEFIy5YZeHq17LEDA.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/oYaAX5himiTPYuN8vLWnqBbfD2s.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/lEJLP4R0yuCaMCjSXYHtJw72M.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/cRJyLNuTJR5jbyKzGi33wU9cqIQ.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/1ZFS7N918ojhhd0nQWdj3jz4w.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/A0Wcc7NgXMjUuFdquHDrIZpzZw0.woff2", weight: "600"}]}, ...CountdownFonts], {supportsExplicitInterCodegen: true})